import React from "react";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import LpFormShort from "../../../components/ConversionPath/LpFormShort";
import Image from "../../../components/image";
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import { demoTestimonials } from "../../../data/restaurant-data";
import TrustedNumnbers from "../../../components/TrustedNumbers/TrustedNumbers";
import { terminalTrustedNumbers } from "../../../data/terminal-landing-data";
import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
import Badges from "../../../components/ConversionPath/Badges";

import ogImage from "../../../images/global_assets/og-image.png";
import LeadForm from "../../../components/Forms/LeadForm";
import data from "../../../data/forms/lead-form";
import { extractDefaultValues } from "../../../utils/form-utils";
import { lineOfBusiness } from "../../../constants";

export default function DemoPageLp() {
  return (
    <LpLayout phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="Learn more | SpotOn"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="demo__content-wrapper">
          <div className="form-wrapper">
            {/* <LpFormShort */}
            {/*  subHeader="Adapt and grow your restaurant with an end-to-end solution built by */}
            {/* restaurateurs." */}
            {/*  formId="d4131808-6648-449c-bce6-16293f726d13" */}
            {/*  thankYouPath="/associations/thank-you" */}
            {/* /> */}

            <LeadForm
              formName="Lead Form"
              displayForm
              formData={data}
              initialValues={extractDefaultValues(data)}
              header="Get your free demo"
              subHeader="Adapt and grow your restaurant with an end-to-end solution built by
             restaurateurs."
              onValuesChange={() => true}
              useFormValueAsDataLayerEventName
              formValueForDataLayerEventName="industry"
              dataLayerEventNameAppendix="-lead"
              withLineOfBusinessOption={false}
              lineOfBusiness={lineOfBusiness.HOSPITALITY}
              thankYouPath="/associations/thank-you"
            />
          </div>
        </div>
        <Image imageName="demo_image.png" className="demo__heading-img" />
        <section style={{ marginTop: 120 }}>
          <Badges />
          <TrustedNumnbers numbersArray={terminalTrustedNumbers} />
        </section>
        <section style={{ marginTop: 60 }}>
          <TestmonialReviews sectionData={demoTestimonials} />
        </section>
      </section>
      <section style={{ marginBottom: 40 }}>
        <PartnerLogos />
      </section>
    </LpLayout>
  );
}
